
import './App.css';

function App() {
  return (
    <div className="view">


      <div className='paralax-1'>

        {/* --- */}

        <div className='nav'>
          {/* -- */}
          <div className='icon-logo'></div>
          {/* -- */}
          <div className='explore-nav'>
            <div className='explore-nav-link'>HOME</div>
            <div className='explore-nav-link'>ADS</div>
            <div className='explore-nav-link'>TOKENOMICS</div>
            <div className='explore-nav-link'>FIRST ROUND</div>            
            <div className='explore-nav-link'>DOCUMENTATION</div>
          </div>
          {/* -- */}
          <div className='call-action-nav'>
            <div className='call-action-nav-bt'>
              PLAY NOW
            </div>
          </div>
          {/* -- */}
        </div>

        {/* --- */}

        <div className='paralax-1-logo'></div>

        {/* --- */}

        <div className='paralax-1-bt'>PLAY NOW</div>

        {/* --- */}

      </div>


      <div className='contain-1'>
        <div className='contain-1-div-1'></div>
        <div className='contain-1-div-1'></div>
        <div className='contain-1-div-1'></div>
      </div> 


      <div className='contain-2'>
        <div className='contain-2-div-1'></div>
        <div className='contain-2-div-1'></div>
      </div> 


      <div className='contain-3'>
      Container 4
      </div> 
     
    </div>
  );
}

export default App;
